import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { ANIMATION, BREAKPOINTS, LAYOUT } from '../styles/cssVariables'
import SEO from '../components/layout/seo'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import Tag from '../components/ui-kit/tag'
import SvgProfileSize from '../images/icons/profile-size.svg'
import SvgProfileLocation from '../images/icons/profile-location.svg'
import SvgProfileWebsite from '../images/icons/profile-website.svg'
import { formatUrl, numberWithCommas } from '../utils/utils'
import SocialMediaIconsProfile from '../components/ui-kit/socialMediaIconsProfile'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'

const PageWrapper = styled.div`
  background-color: #f7f9fa;
  padding-top: 75px;
  padding-bottom: 175px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 32px;
    padding-bottom: 140px;
  }
`

const PageContainer = styled(Container)`
  > div {
    align-items: flex-start;

    @media (max-width: ${BREAKPOINTS.med}px) {
      flex-direction: column;
      filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
    }
  }
`

const ProfileMetadata = styled.div`
  flex: 3;

  border-radius: 4px;

  background-color: #fff;

  @media (min-width: ${BREAKPOINTS.medUp}px) {
    flex: 4;
  }

  @media (min-width: ${BREAKPOINTS.med}px) {
    margin-right: 30px;
    filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const MetadataPanel = styled.div`
  &:first-child {
    padding: 50px 40px 36px;
    border-bottom: 1px solid #eef0f3;
  }
  &:last-child {
    padding: 40px 40px 55px;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    &:first-child {
      padding: 30px ${LAYOUT.gutterPageMobile}px 36px;
    }
    &:last-child {
      padding: 40px ${LAYOUT.gutterPageMobile}px 0;
    }
  }
`

const MetadataBrand = styled.div`
  text-align: center;
`

const Logo = styled(GatsbyImage)`
  border-radius: 4px;
  margin: auto;
`

const ContainerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
  margin-bottom: calc(12px);
`


const TagCompressed = styled(Tag)`
  display: inline-block;
  margin: 3px !important;
  padding: 4px 8px 5px;
  line-height: 1.6;
`





const MetadataBasic = styled.div`
  margin-top: 40px;

  > p,
  a {
    font-size: 14px;
    color: var(--color-heading);

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  a {
    transition: color ${ANIMATION.speedFast} ease;
    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 1em;
    vertical-align: middle;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    > p,
    a {
      font-size: 13px;
    }
  }
`

const ContactButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
  width: fit-content;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);

  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  background-color: var(--color-skobeloff);
  color: #fff !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const TagContainer = styled.div`
  margin: -3px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`

const ProfileMain = styled.div`
  flex: 7;

  border-radius: 4px;

  background-color: #fff;

  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.med}px) {
    filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

const ProfileImage = styled(GatsbyImage)`
  @media (max-width: ${BREAKPOINTS.med}px) {
    display: none;
  }
`

const ProfileImageMobile = styled(GatsbyImage)`
  @media (min-width: ${BREAKPOINTS.med}px) {
    display: none;
  }
`

const ProfileContent = styled.div`
  padding: 52px 60px 75px;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 35px 40px 55px;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding: 40px ${LAYOUT.gutterPageMobile}px ${LAYOUT.gutterPageMobile}px;
  }
`

const ProfileContentContainer = styled(Container)`
  padding: 0;
`

const ContentText = styled.div`
  margin-bottom: 60px;

  p {
    color: var(--color-body);
    margin-bottom: 1rem;
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  a {
    color: var(--color-skobeloff);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px 5px;

  img {
    border-radius: 4px;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`

const ProfileName = styled.h1`
  font-size: 22px;
  line-height: 1.5;
  color: var(--color-heading);


`

const HeadingSecondary = styled.h2`
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.85;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.sml}px) {
      font-size: 16px;
      line-height: 2;
    }
`

const ProfileSecondaryButton = styled(Link)`
margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  cursor: pointer;
  transition: color 275ms ease;
  text-deocration: none;
  &:after {
    display: block;
    content: "";
    border-bottom: 2px solid var(--color-violet);
    transform: scaleX(0);
    transition: transform 275ms ease-in-out;
    transform-origin: 100% 50%;
    margin-top: 0.3em;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }

   
  margin-top: 30px;
  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 13px;
  
  }
`
const ButtonColumn= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

class BrandProfileTemplate extends React.Component {
  render() {
    const profile = get(this.props, 'data.contentfulBrandProfile')

    return (
      <Layout location={this.props.location}>
        {/* <SEO pageTitle={`${profile.name}`} ogImage={profile?.image?.gatsbyImageData?.images?.fallback?.src} /> */}
        <SEO pageTitle={`${profile.name}`} />

        {profile.image && (
          <ProfileImageMobile
            alt={profile.image.title}
            image={profile.image.gatsbyImageData}
          />
        )}

        <PageWrapper>
          <PageContainer flex={true} width={12}>
            <ProfileMetadata>
              <MetadataPanel>
                <MetadataBrand>
                  {profile.logo && (
                    <Logo
                      alt={profile.logo.title}
                      image={profile.logo.gatsbyImageData}
                    />
                  )}
                  <ContainerName>
                    <ProfileName>{profile.name}</ProfileName>
                   
                  </ContainerName>
     
                  <TagCompressed type="impact">
                    {profile.primaryImpact}
                  </TagCompressed>
                  <ButtonColumn>
                  <ContactButton href={profile.websiteUrl ? (`${profile.websiteUrl}`) : (`${profile.website}`)} target="_blank"
            rel="nofollow">
                    
                      Website
                      <Arrow />
                  </ContactButton>
                  <ProfileSecondaryButton to="/brand-directory">Back to Directory<Arrow /></ProfileSecondaryButton>
                  </ButtonColumn>
                </MetadataBrand>

                <MetadataBasic>
     
                  {profile.locationData && (
                    <p>
                      <SvgProfileLocation />
                      {profile.locationData.locationText}
                    </p>
                  )}
                  {/* {profile.website && (
                    <a
                      href={
                        profile.websiteUrl
                          ? profile.websiteUrl
                          : profile.website
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <SvgProfileWebsite />
                      {formatUrl(profile.website)}
                    </a>
                  )} */}
                </MetadataBasic>



                <SocialMediaIconsProfile
                  name={profile.name}
                  facebookProfileLink={profile.facebookProfileLink}
                  instagramProfileLink={profile.instagramProfileLink}
                  linkedInProfileLink={profile.linkedInProfileLink}
                  // twitterProfileLink={profile.twitterProfileLink}
                />
              </MetadataPanel>

              <MetadataPanel>


                {profile.secondaryImpact && (
                  <>
                    <HeadingSecondary>Impact</HeadingSecondary>
                    <TagContainer>
                      {profile.secondaryImpact.map((impact) => (
                        <TagCompressed key={impact} type="impact">
                          {impact}
                        </TagCompressed>
                      ))}
                    </TagContainer>
                  </>
                )}
              </MetadataPanel>
            </ProfileMetadata>

            <ProfileMain>
              {profile.image && (
                <ProfileImage
                  alt={profile.image.title}
                  image={profile.image.gatsbyImageData}
                />
              )}

              <ProfileContent>
                <ProfileContentContainer align="left" width={11}>
                  <HeadingSecondary>Profile</HeadingSecondary>
                  <ContentText
                    dangerouslySetInnerHTML={{
                      __html: profile.bio.childMarkdownRemark.html,
                    }}
                  />
                  {profile.mindfulCommerceSummaryHeading ? (
                    <HeadingSecondary>{profile.mindfulCommerceSummaryHeading}</HeadingSecondary>
                  ) : (
                    <HeadingSecondary>Why we love this</HeadingSecondary>
                  )}

                  <ContentText
                    dangerouslySetInnerHTML={{
                      __html:
                        profile.mindfulCommerceSummary.childMarkdownRemark.html,
                    }}
                  />
                </ProfileContentContainer>

                {profile.portfolio && (
                  <>
                    <HeadingSecondary>{profile.productImagesHeading}</HeadingSecondary>
                    <PortfolioGrid>
                      {profile.portfolio.map((item) => (
                        <GatsbyImage
                          alt={item.title}
                          image={item.gatsbyImageData}
                          key={item.id}
                        />
                      ))}
                    </PortfolioGrid>
                  </>
                )}
              </ProfileContent>
            </ProfileMain>
          </PageContainer>
        </PageWrapper>
      </Layout>
    )
  }
}

export default BrandProfileTemplate

export const brandProfileQuery = graphql`
  query BrandProfileBySlug($slug: String!) {
    contentfulBrandProfile(slug: { eq: $slug }) {
      name
      slug
      logo {
        title
        gatsbyImageData(layout: FIXED, width: 90)
      }
  
      primaryImpact

      secondaryImpact
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }

      locationData {
        locationText
      }
      website
      websiteUrl
      
      facebookProfileLink
      linkedInProfileLink
      instagramProfileLink
      bio {
        childMarkdownRemark {
          html
        }
      }
      mindfulCommerceSummaryHeading
      mindfulCommerceSummary {
        childMarkdownRemark {
          html
        }
      }
      productImagesHeading
      portfolio {
        id
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
